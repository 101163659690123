<div class="container-fluid">
    <app-header></app-header>

    <div class="container rootCard">
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
</div>



