import { Http } from '@angular/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "../helper/helper.service";
export class ApiService {
    constructor(http, helper) {
        this.http = http;
        this.helper = helper;
        this.apiUrl = environment.apiUrl;
    }
    getOutTx(address, slice) {
        return this.http.get(this.apiUrl + '/addresses/' + address + '/outtxs/' + slice);
    }
    getInTx(address, slice) {
        return this.http.get(this.apiUrl + '/addresses/' + address + '/intxs/' + slice);
    }
    // fetch all block list
    fetch_latest_blocks_api(slice) {
        return this.http.get(this.apiUrl + '/blocks?slice=' + slice);
    }
    // fetch all transaction list
    fetch_transaction_api(slice) {
        return this.http.get(this.apiUrl + '/transactions?slice=' + slice);
    }
    // fetch one block details
    fetch_block_details(slice) {
        return this.http.get(this.apiUrl + '/blocks/' + slice);
    }
    // fetch one transaction details
    fetch_transaction_details(slice) {
        return this.http.get(this.apiUrl + '/transactions/' + slice);
    }
    // fetch one address details
    fetch_address_details(slice) {
        return this.http.get(this.apiUrl + '/addresses/' + slice);
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.Http), i0.ɵɵinject(i2.HelperService)); }, token: ApiService, providedIn: "root" });
