/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header.component";
import * as i3 from "@angular/router";
import * as i4 from "../service/helper/helper.service";
import * as i5 from "../service/auth/auth.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "nav", [["class", "navbar navbar-expand-md navbar-dark bg-dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "navbar-brand"], ["href", "/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["NIT Explorer"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["aria-controls", "navbarsExample04"], ["aria-expanded", "false"], ["aria-label", "Toggle navigation"], ["class", "navbar-toggler"], ["data-target", "#navbarsExample04"], ["data-toggle", "collapse"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "navbar-toggler-icon"]], null, null, null, null, null))], null, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.HeaderComponent, [i3.Router, i4.HelperService, i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i2.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
