import * as i0 from "@angular/core";
export class AuthService {
    constructor() { }
    setUser(user) {
        localStorage.setItem("nestenUserAuth", JSON.stringify(user));
    }
    user() {
        return JSON.parse(localStorage.getItem("nestenUserAuth"));
    }
    removeUser() {
        localStorage.removeItem("nestenUserAuth");
    }
    isGuest() {
        return !this.user();
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(); }, token: AuthService, providedIn: "root" });
