import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import Web3 from 'web3';
import HdKeyring from 'eth-hd-keyring';
import Mnemonic from 'bitcore-mnemonic';
import FileSaver from 'file-saver';
import * as CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
export class HelperService {
    constructor(router) {
        this.router = router;
        this.accountList = [];
        this.accObeservable = {
            accounts: [],
            balances: {}
        };
        this.accountListBalance = {};
        this.accountLoaded = false;
        this.currentAccount = {
            balance: 0,
            symbol: 'NIT'
        };
        this.walletInfo = {
            total: 1,
            selected: 0
        };
        this.globalPause = false;
        this.transactionList = [];
        this.map = new Map();
        this.algorithm = 'aes-256-cbc';
        this.currentBlock = null;
        this.currentHash = null;
        this.currentAddress = null;
        this.newAcc = new EventEmitter();
        this.selAcc = new EventEmitter();
        if (!this.globalPassword) {
            // router.navigate(['login']);
        }
        this.web3 = new Web3(null);
        // this.web3.setProvider('http://18.***.***.0:8545');
        this.web3.eth.defaultGasPrice = 0;
    }
    checkBalance() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.web3.eth.getBalance(this.accountList[this.slectedAddress]).then((x) => {
                this.currentAccount.balance = this.web3.utils.fromWei(x);
                console.log(this.currentAccount.balance);
                return this.currentAccount.balance;
            });
        });
    }
    getBalance(acc) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.web3.eth.getBalance(acc).then((x) => {
                return Promise.resolve(this.web3.utils.fromWei(x));
            });
        });
    }
    getAddress() {
        this.currencAddress = this.accountList[this.slectedAddress];
        return this.currencAddress;
    }
    save() {
        this.web3.eth.accounts.wallet.save(this.globalPassword, 'nit_wallet');
    }
    encrypt(text, passPhrase) {
        let encrypted = CryptoJS.AES.encrypt(text, passPhrase);
        window.localStorage.setItem('nesten_hd', encrypted);
        window.localStorage.setItem('wallet_info', JSON.stringify(this.walletInfo));
    }
    decrypt(passPhrase) {
        try {
            let cyphered = window.localStorage.getItem('nesten_hd');
            var bytes = CryptoJS.AES.decrypt(cyphered.toString(), passPhrase);
            let decrypted = bytes.toString(CryptoJS.enc.Utf8);
            console.log("decrypted : ", decrypted);
            return decrypted;
        }
        catch (error) {
            // alert("Wrong Pin")
            console.log("Error: " + error);
        }
    }
    saveAsFile(words) {
        let blob = new Blob([words], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, "nestenwallet.txt");
    }
    generateSeed() {
        let words = new Mnemonic(Mnemonic.Words.ENGLISH).toString();
        console.log("WORDS", words);
        return words;
    }
    initHdWallet(mnemonic, walletobj) {
        this.hdkeyring = new HdKeyring({
            mnemonic: mnemonic,
            numberOfAccounts: walletobj.total,
        });
    }
    seeMap() {
        console.log("Map:- ", this.map);
        let keys = Array.from(this.map.keys());
        let values = Array.from(this.map.values());
        let list = [
            keys, values
        ];
        console.log(keys, values);
        return (list);
    }
    removeWallet() {
        window.localStorage.removeItem('nesten_hd');
        window.location.reload();
    }
    loadWalletWithSeedWord(passPhrase) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let mnemonic = yield this.decrypt(passPhrase);
            if (!mnemonic) {
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: 'Wrong Key'
                });
                return false;
            }
            else {
                let walletObj = JSON.parse(window.localStorage.getItem('wallet_info'));
                yield this.initHdWallet(mnemonic, walletObj);
                // Till this ok
                this.accountList = yield this.hdkeyring.getAccounts(); // All accounts is in the list
                console.log("LIST ", this.accountList);
                console.log("accountBalList ", this.accountListBalance);
                yield this.balanceFiller();
                this.slectedAddress = walletObj.selected; // By default 0 is selected
                this.router.navigate(['dashboard']);
                return true;
            }
        });
    }
    balanceFiller() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let rtrn = yield this.accountList.map((address) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return yield this.web3.eth.getBalance(address).then((blnc) => {
                    this.accountListBalance[address] = this.web3.utils.fromWei(blnc);
                    return address;
                });
            }));
            Promise.all(rtrn).then(x => {
                this.newAcc.emit(null);
                console.log(x);
            });
        });
    }
    createAddress(passPhrase) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log("helper create address visited");
            let mnemonic = yield this.decrypt(passPhrase);
            if (mnemonic) {
                let walletObj = JSON.parse(window.localStorage.getItem('wallet_info'));
                walletObj.total = Number(walletObj.total) + 1;
                console.log(walletObj.total);
                window.localStorage.setItem('wallet_info', JSON.stringify(walletObj));
                yield this.initHdWallet(mnemonic, walletObj);
                this.accountList = yield this.hdkeyring.getAccounts(); // All accounts is in the list
                console.log("LIST ", this.accountList);
                this.slectedAddress = walletObj.selected; // By default 0 is selected
                yield this.balanceFiller();
                // this.router.navigate(['dashboard']);
                return true;
            }
            else {
                setTimeout(() => {
                    Swal.fire({
                        type: 'error',
                        title: 'Oops...',
                        text: 'Wrong Key'
                    });
                }, 500);
                console.log("EROROR");
                return false;
            }
        });
    }
    selectAddress(addressPosition) {
        let walletObj = JSON.parse(window.localStorage.getItem('wallet_info'));
        if (addressPosition <= walletObj.total) {
            walletObj.selected = addressPosition;
            window.localStorage.setItem('wallet_info', JSON.stringify(walletObj));
            this.slectedAddress = addressPosition;
            console.log("New Default address : ", this.accountList[this.slectedAddress]);
        }
        this.selAcc.emit(null);
    }
    sendNit(to, value, cb) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.web3.utils.isAddress(to)) {
                try {
                    this.globalPause = true;
                    let privateKey = yield this.hdkeyring.exportAccount(this.accountList[this.slectedAddress]);
                    this.web3.eth.accounts.signTransaction({
                        to: to,
                        value: this.web3.utils.toWei(value),
                        gas: 2000000,
                        gasPrice: 0
                    }, '0x' + privateKey).then(tx => {
                        this.web3.eth.sendSignedTransaction(tx.rawTransaction)
                            .on('transactionHash', hash => {
                            console.log("hash", hash);
                        }).on('receipt', receipt => {
                            this.receipt = receipt;
                            this.globalPause = false;
                            console.log("receipt", receipt);
                            cb(true, "Transaction Successfull");
                        }).catch(x => {
                            console.log("ERROR :", x);
                            this.globalPause = false;
                            cb(false, x);
                        });
                    });
                }
                catch (error) {
                    console.log("error", error);
                    cb(false, "Transaction failed");
                }
            }
            else {
                cb(false, "Please enter valid address");
            }
        });
    }
    setBlockNumber(blockNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.currentBlock = blockNumber;
        });
    }
    setTransactionHash(hash) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.currentHash = hash;
        });
    }
    setAddress(address) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.currentAddress = address;
        });
    }
}
