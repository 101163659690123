
<div class="container maindiv">
	
	<!-- Loading -->
	<div class="spinDiv w-100" *ngIf="loading">
		<div>
			<mat-spinner class="mat-spinner-color"></mat-spinner>
			<div class="text-white">Loading...</div>
		</div>
	</div>
	
	<!-- Loading -->
	<div class="row" *ngIf="!loading">
		<div class="card col-md-12 mt-3" style="margin-bottom: 0.5rem !important">

			<div class="container py-3">
				<div class="d-md-flex justify-content-between align-items-center">
					<div class="text-center text-md-left mb-3 mb-md-0">
						<h1 class="h4 mb-0">
							Address : 
							<span id="mainaddress" class="text-size-address text-secondary text-break mr-1" data-placement="top">{{txnHash}}</span>
						</h1>
						<div class="mt-1"></div>
					</div>
				</div>
			</div>
			<div id="overlayMain"></div>
			
			<div id="ContentPlaceHolder1_divSummary" class="container space-bottom-2">
				<div class="row mb-4">
					<div class="col-md-6 mb-3 mb-md-0">
						<div class="card h-100">
							<div class="card-header d-flex justify-content-between align-items-center">
								<h6 class="card-header-title">Overview</h6>
								<div></div>
							</div>
							<div class="card-body">
								<div class="row align-items-center">
									<div class="col-md-4 mb-1 mb-md-0">Balance:</div>
									<div class="col-md-8">{{addressBalance | convertnit}} NIT</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="card" style="margin-bottom: 1.5rem !important">
					<div class="card-header d-flex justify-content-between align-items-center p-0">
						<ul class="nav nav-custom nav-borderless nav_tabs1" id="nav_tabs" role="tablist">
							<li class="nav-item txBack" [ngClass]="{'actiive':allTx==true}" title="Transactions"><a class="nav-link" data-title="Transactions" data-toggle="tab" (click)="goToAddressDetails()">Transactions</a></li>
							<li class="nav-item txBack" [ngClass]="{'actiive':inTx==true}" title="Incoming Transactions"><a class="nav-link" data-title="In Transactions" data-toggle="tab" (click)="showInTx()">Incoming Transactions</a></li>
							<li class="nav-item txBack" [ngClass]="{'actiive':outTx==true}" title="Outgoing Transactions"><a class="nav-link" data-title="Out Transactions" data-toggle="tab" (click)="showOutTx()">Outgoing Transactions</a></li>
						</ul>
					</div>
					<div class="card-body">
						<div class="tab-content">
							<div class="tab-pane fade show active" id="transactions" role="tabpanel" aria-labelledby="transactions-tab" *ngIf="addressTxs.length > 0">
								<div class="d-md-flex justify-content-between mb-4"*ngIf="allTx">
									<p class="mb-2 mt-1 mb-lg-0">
										<i class="fas fa-sort-amount-down text-secondary" title="Oldest First"></i>&nbsp;Latest {{addressTxs.length}} txns
									</p>
								</div>
								<div class="table-responsive mb-2 mb-md-0" *ngIf="allTx">
									<table class="table table-striped table-bordered">
										<thead>
											<tr>
												<th>
													Txn Hash
												</th>
												<th>
													Block
												</th>
												<!-- <th>
													Age
												</th> -->
												<th>
													From
												</th>
												<th>
													To
												</th>
												<th>
													Value
												</th>
												<!-- <th><span id="ContentPlaceHolder1_spanTxFee" title="(Gas Price * Gas Used by Txns) in Ether"><span class="text-secondary">[Txn Fee]</span></span></th> -->
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let txn of addressTxs">
												<td class="media">
													<i class="fa fa-arrow-up " aria-hidden="true" *ngIf="txn.tx.from == txnHash && txn.tx.to != null" style="color:red; padding-right: 3px;"></i>
													<i class="fa fa-arrow-down" aria-hidden="true" *ngIf="txn.tx.from != txnHash && txn.tx.to != null" style="color:green; padding-right: 3px;"></i>
													<i class="fa fa-cog" aria-hidden="true" *ngIf="txn.tx.to == null" style="color:black; padding-right: 3px;"></i>
													<a href="/tx/{{txn.tx.hash}}" style="color: #2f363e;">{{txn.tx.hash.substring(0,20)}}...</a>
												</td>
												<td><a href="/block/{{txn.tx.blockNumber}}" style="color: #2f363e;">{{txn.tx.blockNumber}}</a></td>
												<!-- <td>{{txn.tx.age}}</td> -->
												<td *ngIf = "txn.tx.from == txnHash">{{txn.tx.from.substring(0,20)}}...</td>
												<td *ngIf = "txn.tx.from != txnHash">
													<a  href="/address/{{txn.tx.from}}" style="color: #2f363e;">
														{{txn.tx.from.substring(0,20)}}...
													</a>
												</td>
												<td *ngIf="txn.tx.to != null && txn.tx.to == txnHash">{{txn.tx.to.substring(0,20)}}...</td>
												<td *ngIf="txn.tx.to != null && txn.tx.to != txnHash">
													<a href="/address/{{txn.tx.to}}" style="color: #2f363e;">
														{{txn.tx.to.substring(0,20)}}...
													</a>
												</td>
												<td *ngIf="txn.tx.to == null">Contract Creation</td>
												<td>{{txn.tx.value |  convertnit}}</td>
											</tr>
										</tbody>
									</table>									
								</div>
								<div class="table-responsive mb-2 mb-md-0" *ngIf="inTx && TotalInTransactionPages>0">
									<table class="table table-striped table-bordered">
										<thead>
											<tr>
												<th>
													Txn Hash
												</th>
												<th>
													Block
												</th>
												<!-- <th>
													Age
												</th> -->
												<th>
													From
												</th>
												<th>
													To
												</th>
												<th>
													Value
												</th>
												<!-- <th><span id="ContentPlaceHolder1_spanTxFee" title="(Gas Price * Gas Used by Txns) in Ether"><span class="text-secondary">[Txn Fee]</span></span></th> -->
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let txn of inAddressTxs">
												<td class="media">
													<i class="fa fa-arrow-down" aria-hidden="true"  style="color:green; padding-right: 3px;"></i>
													<i class="fa fa-cog" aria-hidden="true" *ngIf="txn.tx.to == null" style="color:black; padding-right: 3px;"></i>
													<a href="/tx/{{txn.tx.hash}}" style="color: #2f363e;">{{txn.tx.hash.substring(0,20)}}...</a>
												</td>
												<td><a href="/block/{{txn.tx.blockNumber}}" style="color: #2f363e;">{{txn.tx.blockNumber}}</a></td>
												<!-- <td>{{txn.tx.age}}</td> -->
												<td *ngIf = "txn.tx.from == txnHash">{{txn.tx.from.substring(0,20)}}...</td>
												<td *ngIf = "txn.tx.from != txnHash">
													<a  href="/address/{{txn.tx.from}}" style="color: #2f363e;">
														{{txn.tx.from.substring(0,20)}}...
													</a>
												</td>
												<td *ngIf="txn.tx.to != null && txn.tx.to == txnHash">{{txn.tx.to.substring(0,20)}}...</td>
												<td *ngIf="txn.tx.to != null && txn.tx.to != txnHash">
													<a href="/address/{{txn.tx.to}}" style="color: #2f363e;">
														{{txn.tx.to.substring(0,20)}}...
													</a>
												</td>
												<td *ngIf="txn.tx.to == null">Contract Creation</td>
												<td>{{txn.tx.value |  convertnit}}</td>
											</tr>
										</tbody>
									</table>
									<div class="pagination-cnt d-flex justify-content-between" >
										<!-- Pagination for Block -->
										<div>
										<!-- Pagination for count -->
											<nav aria-label="...">
												<ul class="pagination">
													<li class="page-item" *ngIf="pageNumberForInTransaction+1>1">
														<button class="page-link" tabindex="-1" title="Go to Previous" (click)="fetchTransaction('intx',pageNumberForInTransaction-1,0)"><span aria-hidden="True"><i class="fa fa-chevron-left small"></i></span> <span class="sr-only">Previous</span></button>
													</li>
													<li class="page-item">
														<button class="page-link">Page <strong class="font-weight-medium">{{pageNumberForInTransaction+1}}</strong> of <strong class="font-weight-medium">{{TotalInTransactionPages}}</strong></button>
													</li>
													<li class="page-item" *ngIf="TotalInTransactionPages>pageNumberForInTransaction+1">
														<button class="page-link" title="Go to Next" (click)="fetchTransaction('intx',pageNumberForInTransaction+1,0)" ><span aria-hidden="True"><i class="fa fa-chevron-right small"></i></span> <span class="sr-only">Next</span></button>
													</li>
												</ul>
											</nav>
										</div>
										<div>
										<!-- Pagination for jump -->
											<nav aria-label="...">
												<ul class="pagination">
													<li class="page-item" >
														<input type="number" class="form-control"  placeholder="Go to page" [(ngModel)]="InSearchPage">
													</li>
													<li class="page-item">
														<button class="page-link" (click)="jumpEvent(InSearchPage,'intx')">Search</button>
													</li>
													<!-- <li class="page-item" *ngIf="TotalTransactionPages>pageNumberForTransaction+1">
														<button class="page-link" title="Go to Next" (click)="onClickEvent(pageNumberForTransaction,+1,'transctn')" ><span aria-hidden="True"><i class="fa fa-chevron-right small"></i></span> <span class="sr-only">Next</span></button>
													</li> -->
												</ul>
											</nav>
										</div>
									</div>								
								</div>
								<div class="table-responsive mb-2 mb-md-0" *ngIf="outTx && TotalOutTransactionPages>0">
									<table class="table table-striped table-bordered">
										<thead>
											<tr>
												<th>
													Txn Hash
												</th>
												<th>
													Block
												</th>
												<!-- <th>
													Age
												</th> -->
												<th>
													From
												</th>
												<th>
													To
												</th>
												<th>
													Value
												</th>
												<!-- <th><span id="ContentPlaceHolder1_spanTxFee" title="(Gas Price * Gas Used by Txns) in Ether"><span class="text-secondary">[Txn Fee]</span></span></th> -->
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let txn of outAddressTxs">
												<td class="media">
													<i class="fa fa-arrow-up " aria-hidden="true" style="color:red; padding-right: 3px;"></i>
													<i class="fa fa-cog" aria-hidden="true" *ngIf="txn.tx.to == null" style="color:black; padding-right: 3px;"></i>
													<a href="/tx/{{txn.tx.hash}}" style="color: #2f363e;">{{txn.tx.hash.substring(0,20)}}...</a>
												</td>
												<td><a href="/block/{{txn.tx.blockNumber}}" style="color: #2f363e;">{{txn.tx.blockNumber}}</a></td>
												<!-- <td>{{txn.tx.age}}</td> -->
												<td *ngIf = "txn.tx.from == txnHash">{{txn.tx.from.substring(0,20)}}...</td>
												<td *ngIf = "txn.tx.from != txnHash">
													<a  href="/address/{{txn.tx.from}}" style="color: #2f363e;">
														{{txn.tx.from.substring(0,20)}}...
													</a>
												</td>
												<td *ngIf="txn.tx.to != null && txn.tx.to == txnHash">{{txn.tx.to.substring(0,20)}}...</td>
												<td *ngIf="txn.tx.to != null && txn.tx.to != txnHash">
													<a href="/address/{{txn.tx.to}}" style="color: #2f363e;">
														{{txn.tx.to.substring(0,20)}}...
													</a>
												</td>
												<td *ngIf="txn.tx.to == null">Contract Creation</td>
												<td>{{txn.tx.value |  convertnit}}</td>
											</tr>
										</tbody>
									</table>
									<div class="pagination-cnt d-flex justify-content-between">
										<!-- Pagination for Block -->
										<div>
										<!-- Pagination for count -->
											<nav aria-label="...">
												<ul class="pagination">
													<li class="page-item" *ngIf="pageNumberForOutTransaction+1>1">
														<button class="page-link" tabindex="-1" title="Go to Previous" (click)="fetchTransaction('outtxs',0,pageNumberForOutTransaction-1)"><span aria-hidden="True"><i class="fa fa-chevron-left small"></i></span> <span class="sr-only">Previous</span></button>
													</li>
													<li class="page-item">
														<button class="page-link">Page <strong class="font-weight-medium">{{pageNumberForOutTransaction+1}}</strong> of <strong class="font-weight-medium">{{TotalOutTransactionPages}}</strong></button>
													</li>
													<li class="page-item" *ngIf="TotalOutTransactionPages>pageNumberForOutTransaction+1">
														<button class="page-link" title="Go to Next" (click)="fetchTransaction('outtxs',0,pageNumberForOutTransaction+1)" ><span aria-hidden="True"><i class="fa fa-chevron-right small"></i></span> <span class="sr-only">Next</span></button>
													</li>
												</ul>
											</nav>
										</div>
										<div>
										<!-- Pagination for jump -->
											<nav aria-label="...">
												<ul class="pagination">
													<li class="page-item" >
														<input type="number" class="form-control"  placeholder="Go to page" [(ngModel)]="OutSearchPage">
													</li>
													<li class="page-item">
														<button class="page-link" (click)="jumpEvent(OutSearchPage,'outtxs')">Search</button>
													</li>
													<!-- <li class="page-item" *ngIf="TotalTransactionPages>pageNumberForTransaction+1">
														<button class="page-link" title="Go to Next" (click)="onClickEvent(pageNumberForTransaction,+1,'transctn')" ><span aria-hidden="True"><i class="fa fa-chevron-right small"></i></span> <span class="sr-only">Next</span></button>
													</li> -->
												</ul>
											</nav>
										</div>
									</div>									
								</div>
							</div>
							<div class="tab-pane fade show active d-flex justify-content-center" *ngIf="addressTxs.length == 0 && allTx">
								<p>No transactions to show.</p>
							</div>
							<div class="tab-pane fade show active d-flex justify-content-center" *ngIf="(addressTxs.length == 0 || inAddressTxs.length == 0) && inTx">
								<p>No Incoming transactions to show.</p>
							</div>
							<div class="tab-pane fade show active d-flex justify-content-center" *ngIf="(addressTxs.length == 0 || outAddressTxs.length ==0) && outTx">
								<p>No Outgoing transactions to show.</p>
							</div>							
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</div>
